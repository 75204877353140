.image-popup-outer{
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: #333d4bd9;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 2;
}

.image-popup-inner {
    position: relative;
    height: 90vh;
    border-radius: 1vw;
    scroll-behavior: smooth;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow-y: scroll;
}

/* fix popup image height on mobile landscape issue */

.image-popup-inner img{
    object-fit: contain;
    height:100%;
    border-radius: 1vw;
}

.image-popup-button {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 10px;
    right: 10px;
    width: 50px;
    height: 50px;
    cursor: pointer;
    border-radius: 50%;
    background-color: rgba(255, 255, 255, 0.302);
    border: 1px solid gray;
}

.image-popup-button:hover {
    background-color: rgba(255, 255, 255, 0.6);
}


@media(max-width:1000px){
    .image-popup-inner img{
        width:100%;
        height:auto;
    }
}
