.videos{
    width:100%;
    margin-bottom: 30vh;
}

.videos-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    align-items: flex-start;
    margin:0;
}

@media(max-width: 1200px){
    .videos{
        margin-bottom: 20vh;
    }
}

@media(max-width: 1000px){
    .videos{
        margin-bottom: 15vh;
    }
}

