.resume-popup-outer{
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: #333d4bd9;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 2;
}

.resume-image-inner {
    position: relative;
    height: 90%;
    overflow-y: scroll;
}

.resume-img {
    width: 700px;
}

.resume-popup-button {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 10px;
    right: 10px;
    width: 50px;
    height: 50px;
    cursor: pointer;
    border-radius: 50%;
    background-color: rgba(255, 255, 255, 0.302);
    border: 1px solid gray;
}

.resume-popup-button:hover {
    background-color: rgba(255, 255, 255, 0.6);
}

.resume-popup-button img{
    object-fit: contain;
    height:100%;
}

.download-resume-outer {
    background-color: transparent;
    height: 10%;
    display: flex;
    align-items: center;
}

@media(max-width: 700px) {
    .resume-image-inner {
        height: auto;
        /* width: 100%; */
    }
    
    .resume-img {
        width: 100vw;
    }
}
