.reviews{
    color: #333d4b;
    width: 50%;
    padding-bottom: 10vh;
}

.reviews p {
    font-size: larger;
}

.reviews a {
    color: rgba(90, 167, 192, 0.885);
}

@media(max-width: 1200px) {
    .reviews{
        width: 60%;
    }
}

@media(max-width: 1000px) {
    .reviews{
        width: 70%;
    }
}

@media(max-width: 800px) {
    .reviews{
        width: 80%;
    }
}

@media(max-width: 500px) {
    .reviews{
        width: 90%;
    }
}