.gallery{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    width:80vw;
}

.carousel-outer{
    width:100%;
    display:flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 5vh
}

.slider-outer {
    background-color: rgba(90, 167, 192, .181);
    width: 100vw;
    padding: 2vh 0;
    margin: 10vh 0 15vh 0;
    display:flex;
    justify-content: center;
}

.slider-inner{
    height: 100%;
    width:40%;
}

@media(max-width:1784px){
    .gallery{
        width:90vw;
    }
}

@media(max-width:1600px){
    .gallery{
        width:80vw;
    }
}

@media(max-width:1350px){
    .gallery{
        width:90vw;
    }
}

@media(max-width:1200px){
    .gallery{
        width:100vw;
    }
}

/* @media(max-width:1135px){
    .gallery{
        width:100vw;
    }
} */


