.slider-image{
    height: 600px;
    width:450px;
    object-fit: cover;
    object-position: top;
    transition: 1s;    
}

.alice-carousel__stage-item img:hover{
    cursor: pointer;
    transform: scale(1.05);
    transition: 1s;
}
.alice-carousel .animated {
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
  }
  
  .alice-carousel .animated-out {
    z-index: 1;
  }
  
  .alice-carousel .fadeOut {
    -webkit-animation-name: fadeOut;
    animation-name: fadeOut;
  }
  
  @-webkit-keyframes fadeOut {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
      visibility: hidden;
    }
  }
  
  @keyframes fadeOut {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
      visibility: hidden;
    }
  }
  .alice-carousel {
    position: relative;
    width: 100%;
    margin: auto;
    direction: ltr;
  }
  
  .alice-carousel__wrapper {
    position: relative;
    overflow-x: hidden;
    overflow-y: hidden;
    box-sizing: border-box;
    width: 100%;
    height: auto;
  }
  
  .alice-carousel__stage {
    position: relative;
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    white-space: nowrap;
    transform-style: flat;
    -webkit-transform-style: flat;
    backface-visibility: hidden;
    -webkit-backface-visibility: hidden;
  }
  .alice-carousel__stage-item {
    position: relative;
    display: inline-block;
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    vertical-align: top;
    white-space: normal;
    line-height: 0;
  }
  .alice-carousel__stage-item * {
    line-height: initial;
  }
  .alice-carousel__stage-item.__hidden {
    opacity: 0;
    overflow: hidden;
  }

  .next-arrow, .prev-arrow{
    display: flex;
    align-items: center;
  }

  .next-arrow img{
    width:30px;
  }

  .prev-arrow img {
    width: 30px
  }

.alice-carousel__prev-btn:hover,.alice-carousel__next-btn:hover {
    background-color: rgba(90, 167, 192, 0.743);
}
.alice-carousel__prev-btn,.alice-carousel__next-btn {
    position: absolute;
    text-align: left;
    background-color: rgba(90, 167, 192, 0.5);
    top: 35%;
    width: 50px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    cursor: pointer;
}
.alice-carousel__next-btn {
    right: 15px;
}
.alice-carousel__prev-btn {
    left: 15px;
}
  
  .alice-carousel__dots {
    margin: 30px 3px 5px;
    padding: 0;
    list-style: none;
    text-align: center;
  }
  .alice-carousel__dots > li {
    display: inline-block;
  }
  .alice-carousel__dots-item:not(.__custom) {
    width: 8px;
    height: 8px;
    cursor: pointer;
    border-radius: 50%;
    background-color: rgba(90, 167, 192, 0.461);
  }
  .alice-carousel__dots-item:not(.__custom):not(:last-child) {
    margin-right: 20px;
  }
  .alice-carousel__dots-item:not(.__custom):hover, .alice-carousel__dots-item:not(.__custom).__active {
    background-color: rgb(69, 126, 146);
  }
  
  .alice-carousel__slide-info {
    position: absolute;
    top: 20px;
    right: 20px;
    display: inline-block;
    padding: 5px 10px;
    color: #465798;
    border-radius: 5px;
    background-color: rgba(224, 228, 251, 0.6);
  }
  .alice-carousel__slide-info-item {
    vertical-align: middle;
    line-height: 0;
  }

  @media(max-width: 1300px){
    .slider-inner{
        width:60%;
    }
  }

  @media(max-width: 750px){
    .slider-inner{
        width:85%;
    }
  }

  @media(max-width: 550px){
    .slider-inner{
        width:100%;
    }
    .slider-image{
        width:100vw;   
    }
  }