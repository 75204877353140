.home{
    height:122vh;
    width:100vw;
    display:flex;
    object-fit: cover;
    justify-content: center;
    align-items: flex-start;
    /* background-color: rgba(38, 21, 28, 0.329); */
    background-color: rgba(83, 55, 55, 0.294);

}

.home img {
    width:100vw;
    height: 100vh;
    object-fit: cover;
    object-position:top;
    position:fixed;
    z-index: -1;
}

.h1-div {
    font-family: 'Louis George Cafe';
    width:100vw;
    height: 75vh;
    display:flex;
    align-items:flex-end;
    justify-content: center;
    color:rgba(255, 255, 255, 0.697);
    font-size: 7.5vw;
    display: flex;
    justify-content: space-between;
    margin: 0 1vw;
}


@media(max-width:600px){
    .h1-div{
        padding-top: 20vh;
        font-size: 10.5vw;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
   .first-name{
        width: 100%;
        text-align: left;
    }
    .last-name{
        width: 100%;
        text-align: right;
    }
}