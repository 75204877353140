.about{
    width:100vw;
    display:flex;
    align-items:flex-end;
    justify-content: space-evenly;
    background-color: rgba(90, 167, 192, .181);
    margin-bottom: 15vh;
}

.resume-button {
    padding: .5rem 1rem;
    font-size: .8rem;
    border: none;
    background-color: rgb(177, 118, 118);
    border: 1px solid white;
    cursor:pointer;
    color: white;
    transition: .2s;
    margin-bottom: 20px;
    border-radius: 10px;
}

.resume-button:hover {
    background-color: white;
    transition: .2s;
    color: rgb(177, 118, 118);
    border: 1px solid rgb(177, 118, 118);
}

.download-resume-outer {
    background-color: #333d4b;
    border-radius: .2rem;
}

.download-resume {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: white;
    padding: .2rem .5rem;
    cursor: pointer;
    border-radius: .2rem;
    transition: .5s;
}

.download-resume:hover {
    background: rgba(255, 255, 255, 0.736);
    transition: .5s;
}

.download-resume a{
    text-decoration: none;
    color: #333d4b;
    margin-right:.5rem;
    font-weight: 600;
}

.download-resume img {
    width: 30px;
}

.about-headshot{
    width: 400px;
    float:right;
    position: absolute;
    bottom: 0;
    right: 0;
}

.about-banner-div{
    color: rgb(177, 118, 118);
    line-height: 1.5rem;
    width: 98vw;
    text-align: center;
    position: relative;
}
.about-banner-div .text-outer {
    width: 100%;
    height: 100%;
}

.about-banner-div .text-outer p{
    /* padding: 0 1vw; */
    text-align: justify !important;
}

.about-banner-div .text-outer p:first-child {
    padding-right: 400px;
}
.about-banner-div .text-outer p:nth-child(2) {
    padding-right: 400px;
}
.about-banner-div .text-outer p:last-child {
    padding-right: 400px;
}

.about-banner-div p{
    font-size: 1.3rem;
    line-height: 1.3;
    margin-top: 0;
    /* width: 100%; */
}

/* @media(max-width:1800px) {
    .about-banner-div .text-outer p:first-child {
        padding-right: 360px;
    }
    .about-banner-div .text-outer p:last-child {
        padding-right: 360px;
    }
} */

@media(max-width:1510px){
    .about-banner-div p{
        font-size: 1.1rem;
    }
    /* .about-headshot {
        width: 22vw;
    } */
    .about-headshot{
        width: 380px;
    }
}

@media(max-width:900px){
    .about-banner-div .text-outer p:first-child {
        padding: 0 20px;
    }
    .about-banner-div .text-outer p:nth-child(2) {
        padding-right: 200px;
        padding-left: 20px;
    }
    .about-banner-div .text-outer p:last-child {
        padding-right: 240px;
        padding-left: 20px;
    }
    .about-headshot {
        width: 200px;
    }
}
@media(max-width:750px){
    .about-headshot {
        width: 250px;
    }
    .about-banner-div .text-outer p:last-child {
        padding-right: 300px;
    }
}

@media(max-width: 604px){
    .about-banner-div .text-outer p:last-child {
        margin-bottom: 90px;
        padding-right: 20px;
    }
    .about-headshot {
        width: 28vw;
    }
    .resume-button{
        margin-bottom: 90px;
    }
    .about-banner-div .text-outer p:nth-child(2) {
        padding-right: 20px;
        padding-left: 20px;
    }
}
@media(max-width: 493px){
    .about-headshot {
        width: 31vw;
    }
}
@media(max-width: 365px){
    .about-headshot {
        width: 0;
    }
    .about-banner-div .text-outer p:last-child {
        padding-right: 20px;
    }
}
