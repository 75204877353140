.contact{
    width:100vw;
    display:flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    background-color: rgba(168, 112, 112, 0.8);
}
.contact .headshot-div {
    height: 100%;
    /* border-left: 3px solid white; */
    background-color: black;
}

.headshot-img{
    height: 40vh;
}

.banner-contact-div{
    width:100%;
    height: 100%;
    padding : 0 5vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    color: white;
    line-height: 1.5rem;
  
}

.contact-content {
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.form {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-evenly;
    width:40%;
    height:35vh;
}

.input {
    width: 100%;
    font-family: Louis George Cafe;
    border-radius: .2rem;
    border: none;
    padding:5px;
}

.input.message{
    height:15vh;
}

.submit-button-div{
    width:100%;
    display: flex;
    justify-content: center;
}

.submit-button-div input {
    padding: .3rem 1.5rem;
    border-radius: .2rem;
    border: none;
    transition: .3s;
    background-color: white;
    color: rgb(168, 112, 112);
    box-shadow: -2px 2px rgb(137, 93, 93);
    cursor: pointer;
}

.submit-button-div input:hover {
    box-shadow: none;
    transform: translate(-2px,2px);
    transition: .3s;
}

.logos {
    display: flex;
    justify-content: space-evenly;
}

.logos img{
    width: 40px;
    transition: .3s;
}
.logos img:hover{
    cursor:pointer;
    transform: scale(1.5);
    transition: .3s;
}
.socials{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width:50%;
}

.profiles {
    display: flex;
    flex-direction: column;
}

.profiles a{
    color: white;
    text-decoration: none;
    transition: .5s;
}

.profiles a:hover{
    transform: scale(1.2);
    transition: .5s;
}

@media(max-width:1000px){
    .form {
        height:300px;
    }    
    .profiles a{
        font-size: larger;
    }
    .contact h2 {
        font-size: 1.8rem;
    }
    .banner-contact-div {
        padding: 2vw 1vw;
    } 
    .contact .headshot-div img {
        width: 40vw;
        object-fit: cover;
        object-position: top;
    }
}

@media(max-width:660px){
    .contact .headshot-div img {
        width:0;
    }
}

@media(max-width:500px){
    .form {
        height:300px;
        width:45%;
    }
}