.navbar{
    height:10vh;
    width: 100vw;
    position:absolute;
    top:0%;
    font-weight: 600;
    text-shadow: 1px 1px 2px rgb(177, 118, 118);
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    z-index: 1;
}

.nav-menu {
    display:flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width:100%;
    margin-left:5vw;
    padding-left: 5px;
}

.nav-item {
    padding-bottom:5px;
    margin-right: 3vw;
    transition: 1s;
    cursor:pointer;
    color: rgb(177, 118, 118);
}

.nav-item.active {
    font-size: 1.8rem;
    transition: .5s;
    color: rgb(255, 255, 255);
}

.nav-item:hover {
    transition: .5s;
    color: rgb(255, 255, 255);
}
.email {
    display: flex;
    font-size: 1rem;
    color: rgb(177, 118, 118);
    padding-left: 0;
}
.email p{
    margin: 40px 5vw 0 0;
}
@media (max-width: 900px){
    .navbar {
        align-items: flex-start;
        margin-top: 5px;
    }
    .nav-menu {
        margin-left: 1vw
    }
    .nav-item {
        margin-right: 2vw;
    }
    .email {
        padding-top: 0px;
        align-items: flex-start;
    }
    .email p {
        margin: 0 1vw 0 0
    }
}
@media (max-width: 700px){
    .navbar {
        font-size: .9rem;
    }
    .nav-item {
        padding-bottom:5px;
        margin: 1vw;
        transition: 1s;
        cursor:pointer;
        color: rgb(177, 118, 118);
        font-size: .8rem;
    }
    .email {
        font-size: .8rem;
    }
}
@media (max-width: 600px){
    .nav-item {
        font-size: 1.1rem;
        margin : 0 2vw;
    }
    .email {
        position: absolute;
        top: 63vh;
        right: 0;
        text-shadow: 1px 1px 1px rgb(119, 79, 79);
        color: rgba(255, 255, 255, 0.7);
        font-size: 3.6vw;
    }
}